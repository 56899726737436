import { NoSsr } from '@mui/material';
import React from 'react';

export default function withNoSsr<P extends object>(
  Component: React.ComponentType<P>,
) {
  return function WithNoSsr(props: P) {
    return (
      <NoSsr>
        <Component {...props} />
      </NoSsr>
    );
  };
}
