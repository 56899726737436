import { LoadingButton } from '@mui/lab';
import { DialogContent, Stack, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import * as Sentry from '@sentry/nextjs';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

import useAuth from '@/components/hooks/useAuth';

export default function Signin() {
  const { login } = useAuth();
  const [username, setUsername] = useState(``);
  const [password, setPassword] = useState(``);

  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const signin = useCallback(async () => {
    setLoading(true);
    try {
      await login(username, password);
    } catch (e) {
      Sentry.captureException(e);
      enqueueSnackbar(`${e}`, { variant: `error` });
      setUsername(``);
      setPassword(``);
      setLoading(false);
    }
    setLoading(false);
  }, [enqueueSnackbar, login, password, username]);

  return (
    <>
      <Dialog open fullWidth maxWidth="xs">
        <DialogTitle>Sign in</DialogTitle>
        <DialogContent>
          <Stack gap={1}>
            <TextField
              variant="outlined"
              placeholder="username"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              placeholder="password"
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyUp={(e) => {
                if (e.code === `Enter`) {
                  signin();
                }
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            onClick={() => {
              signin();
            }}
            loading={loading}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
