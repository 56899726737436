import React, { ReactNode } from 'react';
import useSWRImmutable from 'swr/immutable';

import compose from '@/components/hoc/compose';
import withErrorBoundary from '@/components/hoc/withErrorBoundary';
import withNoSsr from '@/components/hoc/withNoSsr';
import withSuspense from '@/components/hoc/withSuspense';
import useAuth from '@/components/hooks/useAuth';
import {
  MyReferralLinkDocument,
  MyReferralLinkQuery,
  MyReferralLinkQueryVariables,
} from '@/generated/graphql';
import client from '@/graphql/apollo';

export interface ReferralLink {
  referralLink: string | null;
  referralLinkQrCode: string | null;
}

function ReferralLinkFetcher({
  children,
}: {
  children: (
    referralLink: ReferralLink,
    refresh: { (): Promise<void> },
  ) => ReactNode;
}) {
  const { me } = useAuth();
  const { data: result, mutate } = useSWRImmutable(
    [`/referral-link`, me?.id],
    async () => {
      if (!me) {
        return {
          referralLink: null,
          referralLinkQrCode: null,
        };
      }
      return client
        .query<MyReferralLinkQuery, MyReferralLinkQueryVariables>({
          query: MyReferralLinkDocument,
        })
        .then((it) => ({
          referralLink: it.data.me?.referralLink ?? null,
          referralLinkQrCode: it.data.me?.referralLinkQrCode ?? null,
        }));
    },
    {
      suspense: true,
    },
  );

  const refresh = async () => {
    await mutate();
  };

  return <>{children(result, refresh)}</>;
}

export default compose(
  withErrorBoundary,
  withSuspense,
  withNoSsr,
)(ReferralLinkFetcher);
