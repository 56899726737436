import {
  ARTIST_RELATIONS,
  Group,
  OPERATIONS_MANAGERS,
  PRODUCT_TEAM,
  SALES,
  TALENT_SPECIALISTS,
} from '@/components/constants';

export interface AppPermissions {
  // public
  viewCalendar: boolean;
  viewArtists: boolean;

  // exclude sales
  viewShows: boolean;
  viewSignupDrafts: boolean;
  viewSettlements: boolean;
  viewProgrammings: boolean;
  viewVenues: boolean;
  viewArtistSamples: boolean;
  viewArtistInvitations: boolean;

  // operations manager
  editProgrammings: boolean;
  createShow: boolean;
  editTaxRate: boolean;
  editVenue: boolean;

  // sales or operations manager
  viewDraftContract: boolean;
  createDraftContract: boolean;

  // talent specialist
  viewInvoices: boolean;
  editInvoices: boolean;
  editArtistSamples: boolean;
  editShow: boolean;
  viewNtRecs: boolean;
  editNtRecs: boolean;

  // artist relations
  createArtistInvitations: boolean;
  editArtistInvitations: boolean;
  editShowMinor: boolean;
  editArtist: boolean;
}

export const createEmptyPermissions = (): AppPermissions => ({
  viewCalendar: true,
  viewArtists: true,

  viewShows: false,
  viewSignupDrafts: false,
  viewSettlements: false,
  viewProgrammings: false,
  viewVenues: false,
  viewArtistSamples: false,
  viewArtistInvitations: false,

  editProgrammings: false,
  createShow: false,
  editTaxRate: false,
  editVenue: false,

  viewDraftContract: false,
  createDraftContract: false,

  viewInvoices: false,
  editInvoices: false,
  editArtistSamples: false,
  editShow: false,
  viewNtRecs: false,
  editNtRecs: false,

  createArtistInvitations: false,
  editArtistInvitations: false,
  editShowMinor: false,
  editArtist: false,
});

export const mergePermissions = (permissions: AppPermissions[]) => {
  const mergedPermissions: AppPermissions = createEmptyPermissions();

  permissions.forEach((permission) => {
    Object.entries(permission).forEach(([key, value]) => {
      if (key in mergedPermissions) {
        mergedPermissions[key as keyof AppPermissions] =
          mergedPermissions[key as keyof AppPermissions] || value;
      }
    });
  });

  return mergedPermissions;
};

const artistRelationsPermissions: AppPermissions = {
  viewCalendar: true,
  viewArtists: true,

  viewShows: true,
  viewSignupDrafts: true,
  viewSettlements: true,
  viewProgrammings: true,
  viewVenues: true,
  viewArtistSamples: true,
  viewArtistInvitations: true,

  editProgrammings: false,
  createShow: false,
  editTaxRate: false,
  editVenue: false,

  viewDraftContract: false,
  createDraftContract: false,

  viewInvoices: false,
  editInvoices: false,
  editArtistSamples: false,
  editShow: false,
  viewNtRecs: false,
  editNtRecs: false,

  createArtistInvitations: true,
  editArtistInvitations: true,
  editShowMinor: true,
  editArtist: true,
};

const talentSpecialistPermissions: AppPermissions = {
  viewCalendar: true,
  viewArtists: true,

  viewShows: true,
  viewSignupDrafts: true,
  viewSettlements: true,
  viewProgrammings: true,
  viewVenues: true,
  viewArtistSamples: true,
  viewArtistInvitations: true,

  editProgrammings: false,
  createShow: false,
  editTaxRate: false,
  editVenue: false,

  viewDraftContract: false,
  createDraftContract: false,

  viewInvoices: true,
  editInvoices: true,
  editArtistSamples: true,
  editShow: true,
  viewNtRecs: true,
  editNtRecs: true,

  createArtistInvitations: true,
  editArtistInvitations: true,
  editShowMinor: true,
  editArtist: true,
};

const operationsManagerPermissions: AppPermissions = {
  viewCalendar: true,
  viewArtists: true,

  viewShows: true,
  viewSignupDrafts: true,
  viewSettlements: true,
  viewProgrammings: true,
  viewVenues: true,
  viewArtistSamples: true,
  viewArtistInvitations: true,

  editProgrammings: true,
  createShow: true,
  editTaxRate: true,
  editVenue: true,

  viewDraftContract: true,
  createDraftContract: true,

  viewInvoices: true,
  editInvoices: true,
  editArtistSamples: true,
  editShow: true,
  viewNtRecs: true,
  editNtRecs: true,

  createArtistInvitations: true,
  editArtistInvitations: true,
  editShowMinor: true,
  editArtist: true,
};

const productTeamPermissions: AppPermissions = {
  viewCalendar: true,
  viewArtists: true,

  viewShows: true,
  viewSignupDrafts: true,
  viewSettlements: true,
  viewProgrammings: true,
  viewVenues: true,
  viewArtistSamples: true,
  viewArtistInvitations: true,

  editProgrammings: true,
  createShow: true,
  editTaxRate: true,
  editVenue: true,

  viewDraftContract: true,
  createDraftContract: true,

  viewInvoices: true,
  editInvoices: true,
  editArtistSamples: true,
  editShow: true,
  viewNtRecs: true,
  editNtRecs: true,

  createArtistInvitations: true,
  editArtistInvitations: true,
  editShowMinor: true,
  editArtist: true,
};

const salesPermissions: AppPermissions = {
  viewCalendar: true,
  viewArtists: true,

  viewShows: false,
  viewSignupDrafts: false,
  viewSettlements: false,
  viewProgrammings: false,
  viewVenues: false,
  viewArtistSamples: false,
  viewArtistInvitations: false,

  editProgrammings: false,
  createShow: false,
  editTaxRate: false,
  editVenue: false,

  viewDraftContract: true,
  createDraftContract: true,

  viewInvoices: false,
  editInvoices: false,
  editArtistSamples: false,
  editShow: false,
  viewNtRecs: false,
  editNtRecs: false,

  createArtistInvitations: false,
  editArtistInvitations: false,
  editShowMinor: false,
  editArtist: false,
};

const getPermissionByGroup = (group: Group): AppPermissions => {
  switch (group) {
    case ARTIST_RELATIONS:
      return artistRelationsPermissions;
    case TALENT_SPECIALISTS:
      return talentSpecialistPermissions;
    case OPERATIONS_MANAGERS:
      return operationsManagerPermissions;
    case PRODUCT_TEAM:
      return productTeamPermissions;
    case SALES:
      return salesPermissions;
    default:
      return createEmptyPermissions();
  }
};

export const getPermissionsByGroups = (groups: Group[]): AppPermissions =>
  mergePermissions(groups.map((group) => getPermissionByGroup(group)));
