import React, { Suspense } from 'react';

import Progress from '@/components/Progress';

export default function withSuspense<P extends object>(
  Component: React.ComponentType<P>,
  fallback: React.ReactNode = <Progress />,
) {
  return function WithSuspense(props: P) {
    return (
      <Suspense fallback={fallback}>
        <Component {...props} />
      </Suspense>
    );
  };
}
