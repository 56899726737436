import useSWR from 'swr';

import { MeDocument, MeQuery } from '@/generated/graphql';
import client from '@/graphql/apollo';

function useMe() {
  return useSWR(
    [`/me`],
    async () =>
      client
        .query<MeQuery>({
          query: MeDocument,
        })
        .then((it) => {
          if (!it.data.me) {
            return null;
          }
          if (!it.data.me.isStaff) {
            return null;
          }
          return it.data.me;
        }),
    {},
  );
}

export default useMe;
