'use client';

import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

function useErrorSnackbar<Error = any>(error: Error) {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(`${error}`, { variant: `error` });
    }
  }, [error, enqueueSnackbar]);
}

export default useErrorSnackbar;
