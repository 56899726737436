import React from 'react';

export default function compose<P extends object>(
  ...hocList: Array<(_: React.ComponentType<P>) => React.ComponentType<P>>
) {
  return function ComposedComponent(Component: React.ComponentType<P>) {
    return hocList.reduce(
      (WrappedComponent, hoc) => hoc(WrappedComponent),
      Component,
    );
  };
}
