'use client';

import { Button, Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import { ErrorBoundary as NextErrorBoundary } from '@sentry/nextjs';
import { ReactNode } from 'react';

export function ErrorBoundary({ children }: { children?: ReactNode }) {
  return (
    <NextErrorBoundary
      fallback={({ error, resetError }) => (
        <Stack direction="row" justifyContent="center">
          <Stack gap={1}>
            <h2>Oops, there is an error!</h2>
            <Alert severity="error">{error?.message ?? `${error}`}</Alert>
            <Button type="button" onClick={resetError} variant="contained">
              Try again?
            </Button>
          </Stack>
        </Stack>
      )}
    >
      {children}
    </NextErrorBoundary>
  );
}
