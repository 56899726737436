import dayjs from 'dayjs';

export const MIN_DATE = dayjs(`2019-01-01`);
export const MAX_DATE = dayjs(`2030-12-01`);
export const DEFAULT_DOOR_TIME_STRING = `19:00`;
export const DEFAULT_TIMEZONE = `America/New_York`;
export const ARTIST_RELATIONS = `Artist Relations`;
export const TALENT_SPECIALISTS = `Talent Specialists`;
export const OPERATIONS_MANAGERS = `Operations Managers`;
export const PRODUCT_TEAM = `Product Team`;
export const SALES = `Sales`;

export type Group =
  | typeof ARTIST_RELATIONS
  | typeof TALENT_SPECIALISTS
  | typeof OPERATIONS_MANAGERS
  | typeof PRODUCT_TEAM
  | typeof SALES;
