import React from 'react';

import { ErrorBoundary } from '@/components/common/ErrorBoundary';

export default function withErrorBoundary<P extends object>(
  Component: React.ComponentType<P>,
) {
  return function WithErrorBoundary(props: P) {
    return (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    );
  };
}
